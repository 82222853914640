import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PackageActivityTaskService } from './package-activity-task.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    PackageActivityTaskService
  ]
})
export class PackageActivityTaskModule { }

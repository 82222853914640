<div
  data-testid="dialog-alert"
  class="box popup-box">
  <div class="form">
    <div
      *ngIf="data.text"
      data-testid="confirm-message"
      class="popup-message">
      {{ data.text }}
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { ApiService } from '@ronas-it/angular-common';
import { Observable } from 'rxjs';

@Injectable()
export class PackageMatrixService {
  public endpoint: string;

  constructor(
    private readonly apiService: ApiService
  ) {
    this.endpoint = '/package-matrix';
  }

  public delete(id: number): Observable<void> {
    return this.apiService.delete(`${this.endpoint}/${id}`);
  }
}

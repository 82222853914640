import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createEffect } from '@ngrx/effects';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { ActivatedRoute, Data, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AppEffects {
  public navigationEnd$: Observable<Data> = createEffect(
    () => this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }

        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
      tap((data) => this.titleService.setTitle(
        `${this.translateService.instant(data.meta.title)} -
        ${this.translateService.instant('SHARED.META.APP_NAME')}`
      ))
    ),
    { dispatch: false }
  );

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService
  ) { }
}

<div
  data-testid="dialog-confirmation"
  class="box popup-box popup-box-confirmation">
  <h1
    data-testid="confirm-title"
    class="popup-title">
    {{ data.title }}
  </h1>
  <div class="form">
    <div
      *ngIf="data.text"
      data-testid="confirm-message"
      class="popup-message">
      {{ data.text }}
    </div>
    <div class="popup-buttons">
      <div class="row">
        <div class="col col-50 col-mobile-50">
          <button
            (click)="closeButtonClicked()"
            [ngClass]="{
              'popup-button-cancel': data?.cancelButtonType === 'positive' || !data?.cancelButtonType,
              'popup-button-confirm': data?.cancelButtonType === 'negative'
            }"
            type="button"
            data-testid="cancel-button"
            class="popup-button">
            {{ data.cancelButtonText || ('SHARED.DIALOG_CONFIRMATION.BUTTON_CANCEL' | translate) }}
          </button>
        </div>
        <div class="col col-50 col-mobile-50">
          <button
            (click)="confirmButtonClicked()"
            [ngClass]="{
              'popup-button-cancel': data?.confirmButtonType === 'positive',
              'popup-button-confirm': data?.confirmButtonType === 'negative' || !data?.confirmButtonType
            }"
            type="button"
            data-testid="confirm-button"
            class="popup-button">
            {{ data.confirmButtonText || ('SHARED.DIALOG_CONFIRMATION.BUTTON_CONFIRM' | translate) }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { FilterOptions } from '@app/account/shared/base-filter';
import { ApiService } from '@ronas-it/angular-common';
import { ClassGroup } from '@shared/class-group';
import { PaginationResponse } from '@shared/pagination';
import { classToPlain, plainToClassFromExist } from 'class-transformer';
import { isUndefined, omitBy } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PackageHandoverDocumentTypeSortField } from './enums';
import { PackageHandoverDocumentType, PackageHandoverDocumentTypeFilters, PackageHandoverDocumentTypePaginationRequest } from './models';

@Injectable()
export class PackageHandoverDocumentTypeService {
  public endpoint: string;

  constructor(
    private readonly apiService: ApiService
  ) {
    this.endpoint = '/package-handover-document-types';
  }

  public getAll({ filters }: {
    filters?: PackageHandoverDocumentTypeFilters;
  } = {}): Observable<PaginationResponse<PackageHandoverDocumentType>> {
    const request = new PackageHandoverDocumentTypePaginationRequest({ ...filters, all: true });

    return this.apiService.get<PaginationResponse<PackageHandoverDocumentType>>(
      this.endpoint,
      omitBy(
        classToPlain<PackageHandoverDocumentTypePaginationRequest>(request), isUndefined
      )
    )
      .pipe(
        map((response) => plainToClassFromExist(
          new PaginationResponse<PackageHandoverDocumentType>(PackageHandoverDocumentType), response, { groups: [ClassGroup.MAIN] }
        ))
      );
  }

  public search({ page, perPage, sortBy, desc, filters }: {
    page?: number;
    perPage?: number;
    sortBy?: PackageHandoverDocumentTypeSortField;
    desc?: boolean;
    filters?: FilterOptions;
  } = {}): Observable<PaginationResponse<PackageHandoverDocumentType>> {
    const request = new PackageHandoverDocumentTypePaginationRequest({ ...filters, page, perPage, sortBy, desc });

    return this.apiService.get<PaginationResponse<PackageHandoverDocumentType>>(
      this.endpoint,
      omitBy(
        classToPlain<PackageHandoverDocumentTypePaginationRequest>(request), isUndefined
      )
    )
      .pipe(
        map((response) => plainToClassFromExist(
          new PaginationResponse<PackageHandoverDocumentType>(PackageHandoverDocumentType), response, { groups: [ClassGroup.MAIN] }
        ))
      );
  }
}

import {ChangeDetectionStrategy, Component, Inject, NgZone} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'dialog-confirmation',
  templateUrl: 'dialog-confirmation.html',
  styleUrls: ['dialog-confirmation.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogConfirmationComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string;
      text?: string;
      confirmButtonText?: string;
      cancelButtonText?: string;
      confirmButtonType?: 'positive' | 'negative';
      cancelButtonType?: 'positive' | 'negative';
      resultData?: any;
    },
    private readonly dialogRef: MatDialogRef<DialogConfirmationComponent>,
    private ngZone: NgZone
  ) { }

  public closeButtonClicked(): void {
    this.ngZone.run(() => {
      this.dialogRef.close();
    });
  }

  public confirmButtonClicked(): void {
    this.ngZone.run(() => {
      if (this.data.resultData === undefined) {
        this.dialogRef.close(true);
      } else {
        this.dialogRef.close(this.data.resultData);
      }
    });
  }
}

import { AuthCredentials, AuthResponse, AuthService as CommonAuthService } from '@ronas-it/angular-common';
import { Injectable, Injector } from '@angular/core';
import { User } from '@shared/user';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { configuration } from '@configurations';
import { StatusCodes } from 'http-status-codes';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class AuthService extends CommonAuthService<User> {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  public authorize(credentials: AuthCredentials, remember: boolean): Observable<AuthResponse<User>> {
    return super
      .authorize(credentials, remember)
      .pipe(
        switchMap((authResponse) => this.refreshProfile(authResponse))
      );
  }

  public logout(): Observable<void> {
    return this.refreshToken$
      .pipe(
        take(1),
        switchMap((refreshToken) => this.apiService.post('/logout', {
          [this.authConfig.refreshTokenField]: refreshToken,
          force_remove_edit_mode: true
        }).pipe(
          tap(() => this.unauthorize()),
          catchError((response: HttpErrorResponse) => {
            if (response.status === StatusCodes.UNAUTHORIZED) {
              this.unauthorize();

              return EMPTY;
            }

            return throwError(() => response);
          })
        ))
      );
  }

  public unauthorize(): void {
    localStorage.removeItem(configuration.leftSidebar.storageKey);
    localStorage.removeItem(configuration.userGroupID.storageKey);

    for (const [key] of Object.entries(localStorage)) {
      if (
        key.includes(configuration.filters.qualityIssues.storageKey) ||
        key.includes(configuration.filters.reworkAndDefects.storageKey)
      ) {
        localStorage.removeItem(key);
      }
    }

    super.unauthorize();
  }

  public resetPasswordRequest(email: string): Observable<void> {
    return this.apiService.post('/password-reset', { email });
  }

  public resetPasswordValidateToken(token: string): Observable<void> {
    return this.apiService.post('/password-reset/validate_token', { token });
  }

  public resetPasswordConfirm(token: string, password: string): Observable<Record<'status' | 'access' | 'refresh', string>> {
    return this.apiService.post('/password-reset/confirm', { token, password });
  }

  private refreshProfile(authResponse: AuthResponse<User>): Observable<AuthResponse<User>> {
    return this.userService
      .refreshProfile()
      .pipe(
        map(() => authResponse)
      );
  }
}

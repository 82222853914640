import { AbstractUser } from '@ronas-it/angular-common';
import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { ClassGroup } from '../../class-group';
import { UserGroupEnum, UserStatusEnum } from '../enums';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Project } from '@shared/project';
import { Company } from '@shared/company';

export class User extends AbstractUser {
  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN] })
  public id: number;

  @Expose({ name: 'first_name', groups: [ClassGroup.MAIN, ClassGroup.UPDATING, ClassGroup.CREATING] })
  public firstName: string;

  @Expose({ name: 'last_name', groups: [ClassGroup.MAIN, ClassGroup.UPDATING, ClassGroup.CREATING] })
  public lastName: string;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public email: string;

  @Transform(({ value }) => value ? parsePhoneNumberFromString(value)?.formatInternational() : '', { toClassOnly: true })
  @Transform(({ value }) => value ? parsePhoneNumberFromString(value)?.format('E.164') : '', { toPlainOnly: true })
  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.UPDATING, ClassGroup.CREATING] })
  public phone: string;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.UPDATING, ClassGroup.CREATING] })
  public position: string;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.UPDATING, ClassGroup.CREATING] })
  public company: string;

  @Expose({ name: 'expanded_user_company', groups: [ClassGroup.MAIN, ClassGroup.UPDATING, ClassGroup.CREATING] })
  public expandedUserCompany: Company;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.UPDATING] })
  public password: string;

  @Expose({ name: 'new_password', groups: [ClassGroup.MAIN, ClassGroup.UPDATING] })
  public newPassword: string;

  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public group: UserGroupEnum;

  @Type(() => Project)
  @Expose({ name: 'expanded_projects', groups: [ClassGroup.MAIN] })
  public expandedProjects: Array<Project>;

  @Type(() => Boolean)
  @Expose({ name: 'expanded_is_notifications_enabled', groups: [ClassGroup.MAIN, ClassGroup.UPDATING] })
  public expandedIsNotificationsEnabled: boolean;

  @Expose()
  public status: UserStatusEnum;

  @Exclude()
  public get fullName(): string {
    return `${this.firstName} ${this.lastName}`.trim() || 'User Name';
  }

  @Exclude()
  public get projectsNames(): string {
    return this.expandedProjects
      ?.map(({ name }) => name)
      .reduce((result, item) => `${result}, ${item}`) || '';
  }

  @Exclude()
  public get isApproved(): boolean {
    return this?.status === UserStatusEnum.APPROVED;
  }

  constructor(model: Partial<User> = {}) {
    super();

    Object.assign(this, model);
  }
}

import { QualityIssueStatusEnum } from '@shared/quality-issue';
import { SubtaskStatus } from '@shared/subtask';
import { DateTime } from 'luxon';

export class FloorPlanFilters<T extends QualityIssueStatusEnum | SubtaskStatus = any> {
  public id?: number;
  public search?: string;
  public package?: Array<number>;
  public building?: Array<string>;
  public level?: Array<string>;
  public area?: Array<string>;
  public status?: Array<T>;
  public isDefect?: boolean;
  public user?: Array<number>;
  public startDate?: DateTime;
  public endDate?: DateTime;
  public updatedAtStartDate?: DateTime;
  public updatedAtEndDate?: DateTime;
  public getTotalItemsCount?: boolean;
  public hasFloorPlanArea?: boolean;

  constructor(model: Partial<FloorPlanFilters<T>> = {}) {
    Object.assign(this, model);
  }
}

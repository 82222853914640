import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'dialog-unexpected-error',
  templateUrl: 'dialog-unexpected-error.html',
  styleUrls: ['dialog-unexpected-error.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogUnexpectedErrorOccurredComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      text?: string;
    }
  ) { }
}

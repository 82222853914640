import { Observable } from 'rxjs';
import { Project } from '@shared/project';
import { AccountProjectsDetailsPageActions, AccountProjectsDetailsPageSelectors } from '../../projects/details/shared/store';
import { FilterOptions, FilterQueryParameters } from './models';
import { tap, withLatestFrom } from 'rxjs/operators';
import { ComponentStore } from '@ngrx/component-store';
import { BaseFilterComponentState } from './base-filter.state';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/store';
import { Actions, ofType } from '@ngrx/effects';
import { CustomSelectOption } from '@shared/custom-select';
import { Injector } from '@angular/core';
import { FilterMode } from './enums';

export abstract class BaseFilterComponentFacade<S extends BaseFilterComponentState = BaseFilterComponentState, T = any> {
  public abstract get options$(): Observable<Array<CustomSelectOption<string | number, T>>>;

  public get project$(): Observable<Project> {
    return this.store.select(AccountProjectsDetailsPageSelectors.currentProject);
  }

  public get mode$(): Observable<FilterMode> {
    return this.componentStore.select((state) => state.mode);
  }

  public get filterOptions$(): Observable<FilterOptions> {
    return this.componentStore.select((state) => state.filterOptions);
  }

  public get items$(): Observable<Array<T>> {
    return this.componentStore.select((state) => state.items);
  }

  public get isLoading$(): Observable<boolean> {
    return this.componentStore.select((state) => state.isLoading);
  }

  public get hasNextItems$(): Observable<boolean> {
    return this.componentStore.select((state) => state.items.length / state.page === state.perPage);
  }

  public get parameters$(): Observable<FilterQueryParameters> {
    return this.componentStore.select((state) => ({
      page: state.page,
      perPage: state.perPage,
      desc: state.desc
    }));
  }

  public get isFilterCleared$(): Observable<boolean> {
    return this.componentStore.select((state) => state.isFilterCleared);
  }

  public get isAlwaysSelected$(): Observable<boolean> {
    return this.componentStore.select((state) => state.isAlwaysSelected);
  }

  public updateStateMode: (value: FilterMode) => void;
  public updateIsFilterCleared: (value: boolean) => void;

  protected componentStore: ComponentStore<S>;
  protected store: Store<AppState>;
  protected actions$: Actions;
  protected loadItemsByParametersEffect$: () => Observable<void>;
  protected loadItemsEffect$: () => Observable<void>;
  protected updateStateItems: (items?: Array<T>) => void;
  protected updateIsLoading: (value: boolean) => void;
  protected updateIsAlwaysSelected: (value: boolean) => void;
  protected updateStateFilterOptions: (filterOptions: FilterOptions) => void;
  protected resetStateItems: () => void;

  private updateStateNextPage: () => void;

  constructor(
    protected readonly injector: Injector
  ) {
    this.componentStore = this.injector.get(ComponentStore);
    this.store = this.injector.get(Store);
    this.actions$ = this.injector.get(Actions);

    this.resetState();
    this.registerLoadItemsByParametersEffect();
    this.registerClearFilterEffect();
    this.registerUpdateStateMode();
    this.registerUpdateIsFilterCleared();
    this.registerUpdateStateItems();
    this.registerUpdateIsLoading();
    this.registerUpdateStateFilterOptions();
    this.registerResetStateItems();
    this.registerUpdateStateNextPage();
    this.registerUpdateIsAlwaysSelected();
    this.registerLoadItemsEffect();
  }

  public abstract resetState(): void;

  public updateFilterOptions(value: FilterOptions): void {
    this.updateStateFilterOptions(value);
    this.loadItems();
  }

  public loadItems(): void {
    this.loadItemsEffect$();
  }

  public loadNextPage(): void {
    this.updateStateNextPage();
    this.loadItemsByParameters();
  }

  public resetItems(): void {
    this.resetStateItems();
  }

  public registerUpdateStateMode(): void {
    this.updateStateMode = (value: FilterMode) => this.componentStore
      .updater(
        (state) => ({
          ...state,
          mode: value
        })
      )();
  }

  public registerUpdateIsFilterCleared(): void {
    this.updateIsFilterCleared = (value: boolean) => this.componentStore
      .updater(
        (state) => ({
          ...state,
          isFilterCleared: value
        })
      )();
  }

  public registerUpdateIsAlwaysSelected(): void {
    this.updateIsAlwaysSelected = (value: boolean) => this.componentStore
      .updater(
        (state) => ({
          ...state,
          isAlwaysSelected: value
        })
      )();
  }

  protected abstract registerLoadItemsByParametersEffect(): void;

  protected registerUpdateStateItems(): void {
    this.updateStateItems = (items: Array<T> = []) => this.componentStore
      .updater(
        (state) => ({
          ...state,
          items: [...state.items, ...items]
        })
      )();
  }

  protected registerUpdateIsLoading(): void {
    this.updateIsLoading = (value: boolean) => this.componentStore
      .updater(
        (state) => ({
          ...state,
          isLoading: value
        })
      )();
  }

  protected registerUpdateStateFilterOptions(): void {
    this.updateStateFilterOptions = (filterOptions: FilterOptions) => this.componentStore
      .updater(
        (state) => ({
          ...state,
          filterOptions: new FilterOptions({ ...state.filterOptions, ...filterOptions })
        })
      )();
  }

  protected registerResetStateItems(): void {
    this.resetStateItems = () => this.componentStore
      .updater(
        (state) => ({
          ...state,
          items: [],
          page: 1
        })
      )();
  }

  private loadItemsByParameters(): void {
    this.loadItemsByParametersEffect$();
  }

  private registerUpdateStateNextPage(): void {
    this.updateStateNextPage = () => this.componentStore
      .updater(
        (state) => ({
          ...state,
          page: state.page + 1
        })
      )();
  }

  private registerClearFilterEffect(): void {
    this.componentStore.effect(() =>
      this.actions$.pipe(
        ofType(AccountProjectsDetailsPageActions.clearFilters),
        tap(() => this.updateIsFilterCleared(true))
      )
    );
  }

  private registerLoadItemsEffect(): void {
    this.loadItemsEffect$ = this.componentStore.effect((origin$) =>
      origin$.pipe(
        withLatestFrom(
          this.isAlwaysSelected$
        ),
        tap(([_, isAlwaysSelected]) => {
          if (!isAlwaysSelected) {
            this.resetStateItems();
          }

          this.loadItemsByParameters();
        })
      )
    );
  }
}

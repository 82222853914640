import { AuthModule as CommonAuthModule, RefreshTokenMode } from '@ronas-it/angular-common';
import { AuthService } from './auth.service';
import { configuration } from '@configurations';
import { NgModule } from '@angular/core';
import { AuthenticatedGuard } from './guards';

@NgModule({
  imports: [
    CommonAuthModule.forRoot({
      allowedDomains: configuration.api.allowedDomains,
      disallowedRoutes: configuration.api.disallowedRoutes,
      authService: AuthService,
      unauthenticatedRoute: '/log-in',
      authenticatedRoute: '/account',
      loginEndpoint: '/token',
      refreshTokenEndpoint: '/token/refresh',
      refreshTokenEndpointMethod: 'post',
      refreshTokenMode: RefreshTokenMode.BODY,
      tokenField: 'access',
      refreshTokenField: 'refresh'
    })
  ],
  providers: [
    AuthService,
    AuthenticatedGuard
  ]
})
export class AuthModule { }

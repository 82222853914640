import { DialogActionEnum } from '../enums';

export class DialogResult {
  public type: DialogActionEnum;
  public status: boolean;
  public error?: any;
  public itemID?: number;

  constructor(model: Partial<DialogResult> = {}) {
    Object.assign(this, model);
  }
}

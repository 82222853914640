import { ApiService } from '@ronas-it/angular-common';
import { Injectable } from '@angular/core';
import {
  PackageActivityTask,
  PackageActivityTaskFilters,
  PackageActivityTaskPaginationRequest
} from './models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClassGroup } from '@shared/class-group';
import { PaginationResponse } from '@shared/pagination';
import { classToPlain, plainToClassFromExist } from 'class-transformer';
import { isUndefined, omitBy } from 'lodash';

@Injectable()
export class PackageActivityTaskService {
  public endpoint: string;

  constructor(
    private apiService: ApiService
  ) {
    this.endpoint = '/package-activity-tasks';
  }

  public search({ page, perPage, filters }: {
    page?: number;
    perPage?: number;
    filters?: PackageActivityTaskFilters;
  } = {}): Observable<PaginationResponse<PackageActivityTask>> {
    const request = new PackageActivityTaskPaginationRequest({ ...filters, page, perPage });

    return this.apiService
      .get<PaginationResponse<PackageActivityTask>>(this.endpoint, omitBy(classToPlain<PackageActivityTaskPaginationRequest>(request), isUndefined))
      .pipe(
        map((response) => plainToClassFromExist(new PaginationResponse<PackageActivityTask>(PackageActivityTask), response, { groups: [ClassGroup.MAIN] }))
      );
  }
}

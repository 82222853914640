import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { DialogService } from './dialog.service';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    MatDialogModule
  ],
  providers: [
    DialogService
  ]
})
export class DialogModule { }
